import { parseUserAgent } from '@/utils/userAgent';
import axios from 'axios';


// 埋点
export const trackEvent = (eventName, eventData) => {
	// 获取客户端的浏览器browser和操作系统os信息。如：Chrome/Windows 
	const { os, browser, userAgent } = parseUserAgent();
	
	console.log(`[埋点] 事件名称: ${eventName}`, eventData);

	// 这里可以替换为实际的埋点 API 调用，例如：
	let obj = {
		eventName: eventName,
		eventData: eventData,
		deviceInfo: browser + " / " + os
	};
	console.log(`请求数据：`, obj);
	
	axios.post('/imbmfw/track', obj).then(res => {
	    console.log("埋点保存数据成功：",res)
	}).catch(error => {
	    console.log("报错信息报错：",err);
	})
};