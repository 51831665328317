<template>
  <div class="home-container">
	<van-nav-bar class="flex-auto" title="蔚县便民服务站" />
	
	<van-swipe class="my-swipe" :autoplay="3000" indicator-color="white" lazy-render @change="onSwipeChange">
	  <van-swipe-item v-for="(image,index) in bannerImages" :key="index">
	    <img :src="image.bannerImgUrl" style="max-width: 100%; height: auto;"/>
	  </van-swipe-item>
	</van-swipe>
	
	<van-notice-bar scrollable>
	  蔚县便名服务站，在2025年恭祝全站站友们，新春快乐，合家欢乐。
	</van-notice-bar>
	
	<div style="padding: 8px 10px;">
		<div style="float: left;"><span style="font-weight: bold;">浏览量：</span><span style="color: red;">{{pageviewsNum}}</span> </div>
		<div style="float: right;"><span style="font-weight: bold;">发布量：</span><span style="color: red;">{{pushNum}}</span> </div>
	</div>
	
	<!-- 最新6条 -->
	<van-list :finished="true">
		<van-cell style="text-align: left;font-size:14px;padding:6px 10px"
			v-for="(last, index)  in lastSixList"
			:key="index" 
			icon="right"
		  :title="last.content.slice(0, 13)+'...'"
		  :value="last.updateTime"
		  :info="last"
		  @click="toInfoDetail(last)"
		/>
	</van-list>
	
	<!-- 商家推荐 -->
	 <div class="scroll-container" ref="scrollContainer" @scroll="handleScroll">
	     <div class="image-list">
	       <div v-for="(merchant, index) in merchantList" :key="index" class="image-item" @click="toMerchantDetailPage(merchant)">
	         <img :src="merchant.merchantImgUrl" alt="image" class="image" />
			  <!-- <div :v-if="image.showTag" class="tag">
				{{ image.tagText }}
			 </div> -->
	         <p class="description" style="font-weight: bold;">{{ merchant.merchantName }}</p>
			 <p class="description" style="font-size: 12px;color:#999;">{{ merchant.merchantTypeName }}</p>
	       </div>
	     </div>
	     <!-- <div v-if="loading" class="loading">Loading...</div> -->
	   </div>
	
	<!-- 分类信息 -->
	<van-tabs sticky v-model="active" @click="onClick">
	  <van-tab v-for="(category,index) in this.categoryList" :title="category.name" :key="category.id" >
		  <div class="padding20X paddingX14 van-bgcolor-white">
			<van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
				<van-text-box style="text-align: left;"
					v-for="(information, index)  in informationList"
					:key="index" 
				  :cover="information.url"
				  type="2"
				  :title="information.categoryName"
				  :content="information.content"
				  :sub-content="information.releaseDate"
				>
				  <div slot="tip" class="font-size-2 van-color-text-secondary flex-align">
				    <!-- <van-icon name="browsing-history" color="#C9C9C9" />
				    <span class="margin-left4">345</span> -->
					<a style="font-size: 12px;" v-if="information.articleUrl" :href="information.articleUrl" @click="trackClickEvent">查看手机号</a>
				  </div>
				  <!-- <div>
					<van-tag class="float-left" type="success">{{information.categoryName}}</van-tag>
				  </div> -->
				</van-text-box>
			</van-list>
		  </div>
	  </van-tab>
	</van-tabs>
	
  </div>
</template>

<script>
import http from '../utils/http.js';
import axios from "axios";
import { trackEvent } from '@/utils/track'; // 引入埋点工具函数

export default {
  name: 'HomePage',
  components: {},
  props: {},
  data() {
    return {
		active: 0,
		error: false,
		loading: false,
		finished: false,
		pageSize: 10,
		pageNum: 0,
		// banner图
		bannerImages: [],
		categoryId: 1,
		categoryName: '',
		categoryList: [],
		informationList: [],
		lastSixList: [],
		merchantList: [],
		loading: false, // 是否正在加载
	    page: 1, // 当前页码
	    perPage: 4, // 每次加载的图片数量
	    hasMore: true, // 是否还有更多图片
		pageviewsNum: 0,// 浏览量
		pushNum: 0,//发布量
	}
  },
  setup() {
      // const images = [
      //   'http://imbmfw.cn/prod-api/profile/banner/sndj.png',
      //   'http://imbmfw.cn/prod-api/profile/banner/cxkl.png',
      // ];
      // return { images };
    },
  computed: {},
  watch: {},
  created() {
	  // 获取Banner列表
	  this.getBannerList();
	  // 初始化生命周期
	  this.getBigCategoryList();
	  // 获取最新的六条信息
	  this.getLastSixList();
	  // 获取浏览量
	  this.getTrack();
	  
  },
  mounted() {
	  this.loadImages(); // 初始化加载图片
  },
  methods: {
	  // 获取商户列表
	  getMerchantList(page, perPage){
		  
		  let params = {
		  	  delFlag: 0,
			  pageNum: page,
			  pageSize: perPage
		  }
	  	http.requestPost('/imbmfw/merchant/list',params).then(res => {
	  	      console.log("merchant/list >> ",res)
	  	      if(res.code == 200) {
	  	  		// 添加信息
				this.merchantList.push(...res.rows);
				this.loading = false;
				this.hasMore = res.rows.length < this.pageSize;
	  	      }
	  	  })
	  	  .catch((error) => {
	  	  	this.loading = false;
	  	  	console.log(error)
	  	});	  
	  },
	  // 获取Banner列表
	  getBannerList(){
		let param={
			pageSize: 5,
			pageNum: 1,
			delFlag: 0,
		}
		http.requestPost('/imbmfw/banner/list',param).then(res => {
		  console.log("banner >> ",res)
			if(res.code == 200) {
			  this.bannerImages = res.rows;
			}
		}).catch(err => {
		  console.log(err)
		});  
	  },
	  // 获取浏览量
	  getTrack(){
		 http.requestGet('/imbmfw/track').then(res => {
		   console.log("getTrack >> ",res)
			if(res.code == 200) {
			  this.pageviewsNum = res.data.pageviewsNum;
			  this.pushNum = res.data.pushNum;
			}
		 }).catch(err => {
		   console.log(err)
		 }); 
	  },
	  // 跳转到公众号页面埋点
	  trackClickEvent(){
		trackEvent('button_click', { buttonId: 'gzh_button' });
	  },
	  // 跳转到商家详情页面
	  toMerchantDetailPage(obj){
		  console.log("跳转到商家详情页面 >> merchant=", obj);
		  this.$router.push({
		  	name:'merchantDetail',
		  	params: {merchant: obj},
		  });
	  },
	  // 加载商家图片
	  async loadImages() {
		if (!this.hasMore || this.loading) return;
  
		this.loading = true;
  
		// 模拟异步加载图片
		setTimeout(() => {
			// 获取商家列表
		  this.getMerchantList(this.page, this.perPage);
		  console.log("this.merchantList >> ",this.merchantList)
		  
		  this.page++;
		  this.loading = false;
  
		  // 假设最多加载 5 页
		  if (this.page > 2) {
			this.hasMore = false;
		  }
		}, 1000);
	},

    // 监听滚动事件
    handleScroll() {
      const container = this.$refs.scrollContainer;
      if (container.scrollLeft + container.clientWidth >= container.scrollWidth - 50) {
        this.loadImages(); // 滚动到底部时加载更多图片
      }
    },
	  // 跳转到信息详情页面
	  toInfoDetail(last){
		 console.log("last >>>>>>>>>>>>>>>>>>>>>>>>> ",last)
		 // 本地存储Info，方便后面使用
		 localStorage.setItem('info', JSON.stringify(last));
		 
		 this. $router.push({
			name:'infoDetail',
			params: {info: last},
		});
	  },
	  getLastSixList(){
		  http.requestGet('/imbmfw/information/selectLastSixList').then(res => {
		    console.log("selectLastSixList >>>>>>>>>>>>>>>>>>>>>>>>> ",res)
			if(res.code == 200) {
			  this.lastSixList = res.data;
			}
		  	  
		  }).catch(err => {
		    console.log(err)
		  });
	  },
	  toWxInfo(url){
		// 普通跳转
		this.$router.push(url);
	  },
	  onSwipeChange(index){
		  // this.$toast.success('当前 Swipe 索引：' + index);
	  },
	 //  // 下拉刷新
	 //  onRefresh() {
		// getpro({
		//   num: this.num,
		//   index: this.index,
		// }).then((res) => {
		//   this.prolist = res.msg;
		//   this.isLoading = false;
		//   this.index = 0; //页码也需要初始化
		//   this.finished = false //
		// })
	 //  },
	onLoad() {
		
		this.pageNum ++;
		// 开始异步请求数据
		let params = {
			  categoryId: this.categoryId,
		  	  pageNum: this.pageNum,
		  	  pageSize: this.pageSize
		}
		console.log("加载更多 >>>>>>>>>>>>>>>>>>>>>>>>> params=",params)
		this.selectDesensitizationList(params);
	  },
	  
	  //防抖
	  delay(){
		  
	  },
	  // 点击tab
	  onClick(index, title) {
		  this.informationList = [];
		  this.pageNum = 0;
		  console.log("点击tab >>>>>>>>>>>>>>>>>>>>>>>>> ")
		  
		  this.categoryId = this.categoryList[index].id;
		  // 开始异步请求数据
		  let params = {
				categoryId: this.categoryId,
				pageNum: 1,
		    	pageSize: this.pageSize
		  }
		  this.selectDesensitizationList(params);
		  this.pageNum = params.pageNum;
	  },
	  selectDesensitizationList(params){
	  	http.requestPost('/imbmfw/information/selectDesensitizationList',params).then(res => {
	  	      console.log("selectDesensitizationList >>>>>>>>>>>>>>>>>>>>>>>>> ",res)
	  	      if(res.code == 200) {
	  	  		// 添加信息
	  	  	    // this.informationList = res.rows;
				this.informationList.push(...res.rows);
	  	  		console.log(this.informationList)
	  			this.loading = false
	  			this.finished = res.rows.length < this.pageSize
	  	      }
	  	  })
	  	  .catch((error) => {
	  	  	this.loading = false;
	  	  	console.log(error)
	  	});	  
	  },
	  // 查询大类
	  getBigCategoryList(){
	    http.requestGet('/imbmfw/category/bigList').then(res => {
	      console.log("getCategoryList >>>>>>>>>>>>>>>>>>>>>>>>> ")
	      console.log(res)
	      res.rows.forEach(arr => {
	        let option ={}
	        option.id = arr.categoryId
	        option.name = arr.categoryName
	        this.categoryList.push(option)
	      })
	      console.log("categoryList >>>>>>>>>>>>>>>>>>>>>>>>> ")
	      console.log(this.categoryList)
	  
	    }).catch(err => {
	      console.log(err)
	    });
	  },
  }
}
</script>

<style scoped>
	.tag {
	  position: absolute; /* 绝对定位 */
	  /* position: relative; */ /* 相对定位 */
	  bottom: 59px;
	  background-color: rgba(2,131,239, 0.7); /* 标签背景色 */
	  color: white; /* 标签文字颜色 */
	  padding: 5px 10px; /* 内边距 */
	  font-size: 14px; /* 字体大小 */
	  border-radius: 4px; /* 圆角 */
	  cursor: pointer; /* 鼠标指针 */
	  transition: background-color 0.3s; /* 背景色过渡效果 */
	  width: 50px;
	}
	
	.tag:hover {
	  background-color: rgba(2,131,239, 0.9); /* 鼠标悬停时背景色 */
	}
	.scroll-container {
		position: relative; /* 相对定位 */
		width: 100%;
		overflow-x: auto; /* 横向滚动 */
		white-space: nowrap; /* 防止换行 */
	}
	
	.image-list {
		/* display: inline-block; */
		display: inline-flex; /* 横向排列 */
	    gap: 10px; /* 图片间距 */
	    padding: 10px;
	}
	.image {
	  width: 100px; /* 图片宽度 */
	  height: 100px; /* 图片高度 */
	  object-fit: cover; /* 图片填充方式 */
	  border-radius: 4px; /* 圆角 */
	}
	.description {
	  margin-top: 5px;
	  font-size: 14px;
	  color: #333;
	  text-align: center;
	}
	
	.loading {
	  text-align: center;
	  padding: 10px;
	  font-size: 14px;
	  color: #666;
	}
	
	.grid-container {
	  display: grid;
	  grid-template-columns: repeat(4, 1fr); /* 4 列 */
	  grid-gap: 10px; /* 图片间距 */
	  max-width: 500px; /* 容器最大宽度 */
	  margin: 0 auto; /* 居中 */
	}
	
	.grid-item img {
	  width: 100%;
	  height: auto;
	  display: block;
	  border-radius: 8px; /* 圆角 */
	}
	.my-swipe .van-swipe-item {
	  color: #fff;
	  font-size: 20px;
	  text-align: center;
	}
	.custom-title {
		 text-align: left;
	    margin-right: 4px;
	    vertical-align: middle;
	  }
   .search-icon {
	  font-size: 16px;
	  line-height: inherit;
	}
</style>