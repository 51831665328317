import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import { trackEvent } from '@/utils/track';

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
	{
	    path: '/login',
	    name: 'login',
		component: () => import('../views/LoginView.vue')
	},
	{
	    path: '/merchant',
	    name: 'merchant',
	    component: () => import('../views/MerchantView.vue')
	},
	{
	    path: '/infoDetail',
	    name: 'infoDetail',
	    // route level code-splitting
		// props: route => ({param: route.query.param)),
	    // this generates a separate chunk (about.[hash].js) for this route
	    // which is lazy-loaded when the route is visited.
	    component: () => import('../views/InformationDetailView.vue')
	},
	{
	    path: '/merchantDetail',
	    name: 'merchantDetail',
	    component: () => import('../views/MerchantDetailView.vue')
	},
	{
	    path: '/contact',
	    name: 'contact',
	    component: () => import('../views/ContactCustomerServiceView.vue')
	},
    {
        path: '/push',
        name: 'push',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/PushView.vue')
    }
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})
router.afterEach((to) => {
  trackEvent('page_view', { pageName: to.name });
});
export default router
