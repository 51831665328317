// utils/userAgent.js
export const parseUserAgent = () => {
  const userAgent = navigator.userAgent;

  // 判断操作系统
  const getOS = () => {
    if (/Windows/.test(userAgent)) {
      return 'Windows';
    } else if (/Macintosh/.test(userAgent)) {
      return 'Mac';
    } else if (/Linux/.test(userAgent)) {
      return 'Linux';
    } else if (/Android/.test(userAgent)) {
      return 'Android';
    } else if (/iPhone|iPad|iPod/.test(userAgent)) {
      return 'iOS';
    } else {
      return 'Unknown';
    }
  };

  // 判断浏览器
  const getBrowser = () => {
    if (/Chrome/.test(userAgent) && !/Edg/.test(userAgent)) {
      return 'Chrome';
    } else if (/Firefox/.test(userAgent)) {
      return 'Firefox';
    } else if (/Safari/.test(userAgent) && !/Chrome/.test(userAgent)) {
      return 'Safari';
    } else if (/Edg/.test(userAgent)) {
      return 'Edge';
    } else if (/Opera|OPR/.test(userAgent)) {
      return 'Opera';
    } else {
      return 'Unknown';
    }
  };

  return {
    os: getOS(),
    browser: getBrowser(),
    userAgent, // 返回完整的 userAgent 字符串
  };
};