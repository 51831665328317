<template>
  <div id="app">

    <router-view />
	<!-- /标签导航栏 -->
	<van-tabbar v-model="active" route>
	  <van-tabbar-item icon="wap-home-o" to="/">首页</van-tabbar-item>
	  <van-tabbar-item icon="add" to="/push">发布</van-tabbar-item>
	  <van-tabbar-item icon="smile-o" to="/merchant">商家</van-tabbar-item>
	  
	</van-tabbar>
  </div>
</template>

<script>
export default {
	data() {
		return {
			active: 0
		};
	},
	methods: {
    },
	mounted() {
	},
};

</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

nav {
  color: #fff;
  background-color: #1989fa;
  padding: 10px;
}

</style>
