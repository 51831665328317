import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import Vant from 'vant'
import VantGreen from 'vant-green';

/** 引入组件样式 **/
// import 'vant/lib/index.css'
// 引入全局CSS
// import './assets/styles/main.css' 
import 'vant-green/lib/index.css';


/** 引入需要的组件 **/
import { Form } from 'vant';
import { Field } from 'vant';
import { Button } from 'vant';
import { Divider } from 'vant';
import { Uploader } from 'vant-green';
import { ImagePreview } from 'vant-green';
import { Toast } from 'vant';
import { Tab, Tabs } from 'vant';
import { Grid, GridItem } from 'vant-green';
import { TextBox } from 'vant-green';
import { Tabbar, TabbarItem } from 'vant-green';
import { NoticeBar } from 'vant-green';
import { Tag } from 'vant-green';


// 默认值是true，productionTip设置为 false ，可以阻止 vue 在启动时生成生产提示
Vue.config.productionTip = false

/** 全局注册你需要的组件 **/
Vue.use(VantGreen);
// 表单
Vue.use(Form);
// 属性
Vue.use(Field);
// 按钮
Vue.use(Button);
// 分割线
Vue.use(Divider);
// 文件上传
Vue.use(Uploader);
// 图片预览
Vue.use(ImagePreview);
// 通知栏
Vue.use(NoticeBar);
// 轻提示
Vue.use(Toast);
// 九宫格
Vue.use(Grid).use(GridItem);
// 图文列表
Vue.use(TextBox);
// 标签栏
Vue.use(Tabbar).use(TabbarItem);
Vue.use(Tab).use(Tabs);
// 标签
Vue.use(Tag);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
